import React from 'react';
import {DragSource, DropTarget} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";
import StringUtil from '../../libs/StringUtil';

const typeSortOrder = "sortOrder";

const specTarget = {
    drop(props) {
        return {
            id: props.index,
            index: props.index
        };
    }
};

const specSource = {
    beginDrag(props) {
        return {
            id: props.index,
        };
    },
    endDrag(props, monitor) {
        if (!monitor.didDrop()) {
            return;
        }
        const source = monitor.getItem();
        const target = monitor.getDropResult();

        if (source.id === target.id) {
            return;
        }
        props.reorder(0, source.id, target.index);
    }
};

const collectTarget = connect => ({
    connectDropTarget: connect.dropTarget()
});

const collectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
}); 

const FileManagerRow = props => {
    const record = props.data
    const index = props.index
    const isDeveloper = props.isDeveloper
    const current_folder_id= props.current_folder_id
    const isIE = props.isIE
    const isDragging = props.isDragging
    const isAiUser = props.isAiUser
    let tablePreview = "table_preview"
    
    if (record['folder_private_flg'] != null) {
        if(record['folder_private_flg'] == 1) {
            tablePreview = "table_preview bg-gray"
        }
    }

    const copyFileId = (fileId, fileName) => {
        const clipboardText = fileId
        if(navigator.clipboard == undefined) {
            window.clipboardData.setData('Text', clipboardText)
        } else {
            navigator.clipboard.writeText(clipboardText)
        }
        window.alert(fileName + 'のファイルIDをコピーしました。');
    }

    const render = () => (
        <tr className={tablePreview} key={record['folder_folder_id'] == null ? (record['file_file_id']) : (record['folder_folder_id'])}>
            <td>
                <input type="checkbox" id={"row" + index} data-tying="folder_operation" onChange={(e) => props.checkSelectedRow()} />
                <label htmlFor={"row" + index}></label>
            </td>
            <td>
                {/* 検索結果表示中ではない場合は行選択時にビューワを呼び出し */}
                {record['folder_folder_id'] == null ? (
                    <a
                        className="icon-file copy-link-display"
                        href={'/app/fileviewer?fileId=' + record['file_file_id'] + '&folder_id=' + current_folder_id + '&is_paging=true'}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {record['file_file_name']}
                    </a>
                ) : (
                    <a
                        className="icon-folder"
                        href={'/app/filemanagement?folderid=' + record['folder_folder_id']}
                    >
                        {record['folder_folder_name']}
                    </a>
                )}
                {/* コピーリンク出すか判定 */}
                {(record['folder_folder_id'] == null && isAiUser) && (
                    <span className="copy-link-storage">
                        [<a className="copy-link" onClick={() => copyFileId(record['file_file_id'], record['file_file_name'])}>
                            ファイルIDをコピー
                        </a>]
                    </span>
                )}
            </td>
            <td>
                {/* ファイルかフォルダか判定 */}
                {record['folder_folder_id'] == null ? (
                    <label>
                        {StringUtil.formatDate(new Date(record['file_update_date_time']), 'YYYY-MM-DD hh:mm')}
                        &nbsp;
                        {StringUtil.convertFileSize(record['file_file_size'])}
                    </label>
                ) : (
                    <label>
                        {StringUtil.formatDate(new Date(record['folder_update_date_time']), 'YYYY-MM-DD hh:mm')}
                    </label>
                )}
            </td>
            <td>
                {/* デベロッパーフォルダではない場合並び替え表示 */}
                {isDeveloper === false && props.connectDragSource(
                    <button
                        className="button button-move"
                        style={isIE ? { display: isDragging ? 'none' : 'inline-block' } : {}}
                    />
                )}
            </td>
        </tr>
    )

    if (isIE) {
        props.connectDragPreview(getEmptyImage(), { captureDraggingState: true })
        return props.connectDropTarget(render())
    }

    return props.connectDragPreview(props.connectDropTarget(render()))
}

const FileManagerRowWithDnD = DropTarget(typeSortOrder, specTarget, collectTarget)(
    DragSource(typeSortOrder, specSource, collectSource)(FileManagerRow)
);

export default FileManagerRowWithDnD