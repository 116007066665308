import React from 'react';
import {withRouter} from '../../libs/withRouter';
import StringUtil from '../../libs/StringUtil';
import {ApiEnum, ApiClient, HttpMethod} from '../../api/Api';
import Loading from '../../libs/Loading';
import MessageUtil from '../../libs/MessageUtil';
import FileSearchForm from "../file_search/FileSearchForm.jsx";
import FileDownloadForm from "../file_download/FileDownloadForm.jsx";

/********************************************************************
 *
 * STORAGE-015 ファイル・フォルダ検索結果
 *
 *******************************************************************/
class FileSearchResultForm extends React.Component {

    /**
     * コンストラクタ.
     *  
     * 初期化処理を行います.
     *
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        document.title = '検索結果｜ストレージ';

        this.state = {
            current_folder_id: this.props.router.location.state.current_folder_id,
            current_folder_name: this.props.router.location.state.current_folder_name,
            message: '',
            search_result_list: this.props.router.location.state.search_result_list,
            selected_row_list: [],
            search_word: this.props.router.location.state.search_word,
            ai_user_flg: this.props.router.location.state.ai_flg
        }

		this._checkJs();
    }


	_checkJs(){
	    //動的に生成される要素のためにjsを再読込する
        // check.js
        const script1 = document.createElement("script");
        let s1 = document.getElementById('check_script');
        script1.id = "check_script";
        script1.src = "/common/js/check.js";
        script1.async = true;
        if (s1 != null) {
            document.body.replaceChild(script1, s1);
        }
        // bundle.js
        const script2 = document.createElement("script");
        let s2 = document.getElementById('bundle_script');
        script2.id = "bundle_script";
        script2.src = "/common/js/bundle.js";
        script2.async = true;
        if (s2 != null) {
            document.body.replaceChild(script2, s2);
        }
	}
	
    /**
     * 現在のフォルダーIDをもとにファイル一覧を再検索します.
     *
     */
    _didFinishSearch(argSearchWord, argSearchResultList) {
        this.setState({
            message: '',
            search_result_list: argSearchResultList,
            selected_row_list: [],
            search_word: argSearchWord,
        })
        this._didFinish();
        this._checkJs();
    }


    /**
     * 自画面のチェック状態およびボタンの表示状態をクリアします.
     *
     */
    _didFinish() {
        // HTML側のチェックを外す
        document.getElementById('select_all').checked = false;
        this.state.search_result_list.forEach(function(rec, index) {
            document.getElementById('row' + index).checked = false;
        })
        // ボタン群も消す
        let elements = document.getElementsByClassName('select_list')[0];
        for (let child of elements.children) {
            child.style["display"] = "none";
        }
    }


    /**
     * ファイル管理画面への遷移処理（戻るボタン押下時）.
     *
     */
    backFileManagement() {
        this.props.router.navigate('/app/filemanagement?folderid=' + this.state.current_folder_id);
    }


    /**
     * ファイル管理画面への遷移処理（行選択時）.
     *
     * @param {ファイル管理画面で表示するフォルダID} argFolderId 
     */
    moveFileManagement(argFolderId) {
        this.props.router.navigate('/app/filemanagement?folderid=' + argFolderId);
    }


    /**
     * 行選択処理.
     *
     * 選択済みの行を取得しリストに登録します.
     *
     */
    checkSelectedRow() {
        let tmpSelectedList = [];
        this.state.search_result_list.forEach(function(rec, index) {
            if (document.getElementById('row' + index).checked) {
                tmpSelectedList.push(rec);
            }
        })
        this.setState({selected_row_list: tmpSelectedList});
    }


    /**
     * 選択したファイルをお気に入りに登録します.
     *
     */
    resistFavorite () {
        console.log('resistFavorite/start')

        // ローディング追加
        Loading.addToElement('resist_favorite');

        let that = this;
        let executeCount = 0;
        this.state.selected_row_list.forEach(function(rec) {

            let param;
            if (rec['folder_folder_id'] == null) {
                // ファイルの場合
                param = {"file_id": rec['file_file_id']};
            } else {
                // フォルダの場合
                param = {"folder_id": rec['folder_folder_id']};
            }

            ApiClient.storageApi(
                ApiEnum.Code.BOOKMARK_REGIST, 
                param, 
                HttpMethod.Code.POST)
                .then(json => {
                    executeCount++;

                    // 全てのオブジェクトを処理した場合は後処理を実施
                    if (executeCount === that.state.selected_row_list.length) {
                        // 正常ダイアログを表示
                        window.alert(MessageUtil.getMessage('I00000'));
                        // 一覧を再表示
                        that._didFinish();
                    }
                }).catch(err => {
                    switch (err.errorCode) {
                        case 'E02024':
                        case 'E04001':
                            window.alert(err.errorMessage);
                            break;
                        default:
                            this.props.router.navigate('/app/error', {
                                state: {code: err.errorCode, message: err.errorMessage}
                            });
                            break;
                    }
                }).finally(() => {
                    // ローディング削除
                    Loading.removeFromElement('resist_favorite');
                });
        })

        console.log('resistFavorite/end');
    }


    /**
     * おまとめ画面へ戻る.
     *
     */
    moveTopPage() {
        console.log('moveTopPage/start');
        let link = document.createElement('a');
        link.href = process.env.REACT_APP_OMATOME;
        link.click();
        console.log('moveTopPage/end');
    }

    /**
     * クリップボードにコピー
     *
     */
    copyFileId(fileId, fileName) {
        let clipboardText = fileId;
        if(navigator.clipboard == undefined) {
            window.clipboardData.setData('Text', clipboardText);
        } else {
            navigator.clipboard.writeText(clipboardText);
        }
        window.alert(fileName + 'のファイルIDをコピーしました。');
    }


    /**
     * 画面描画処理.
     *
     */
    render() {
        return (
            <div>
                <header className="header">
                    <button className="button" onClick={(e) => this.backFileManagement()}><span>戻る</span></button>
                    <div className="pagename icon-folder icon-white">{this.state.search_word +  'の検索結果'}</div>
                    <span>
                        <button className="button button-search js-modal" data-id="search"><span>検索</span></button>
                        <button className="button" onClick={(e) => this.moveTopPage()}>トップメニュー 一覧</button>
                    </span>
                </header>

                <main className="main">
                    <span className="label">{this.state.message}</span>

                    <div className="select_menu">
                        <div className="select_all">
                            <input type="checkbox" id="select_all" onChange={(e) => this.checkSelectedRow()} />
                            <label htmlFor="select_all">全て選択</label>
                        </div>
                        <div className="select_list">
                            <span>チェックした項目の</span>
                            <button className="button js-modal" data-tying="folder_operation" data-id="storagedownload" data-checklen="1">ダウンロード</button>
                            <button id="resist_favorite" className="button" data-tying="folder_operation" data-checklen="1" onClick={(e) => this.resistFavorite()}>よく使う書類入れへ</button>
                        </div>
                    </div>

                    <div className="table">
                        {this.renderTable()}
                    </div>

                </main>

                {/* 検索ウィンドウ */}
                <FileSearchForm
                    current_folder_id={this.state.current_folder_id}
                    current_folder_name={this.state.current_folder_name}
                    is_search_result={true}
                    reloadParentForm={this._didFinishSearch.bind(this)}
                />
                {/* ダウンロードウィンドウ */}
                <FileDownloadForm key="FileDownloadForm"
                    reloadParentForm={this._didFinish.bind(this)}
                    selectedList={this.state.selected_row_list}
                />

            </div>


        )
    }


    /**
     * 画面描画処理（テーブル部分）.
     *
     */
    renderTable() {
        const isAiUser = this.state.ai_user_flg
        // 行を定義する
        const rows = this.state.search_result_list.map((record, index) =>
            <tr key={record['folder_folder_id'] == null ? (record['file_file_id']) : (record['folder_folder_id'])}>
                <td>
                    <input type="checkbox" id={"row" + index} data-tying="folder_operation" onChange={(e) => this.checkSelectedRow()} />
                    <label htmlFor={"row" + index}></label>
                </td>
                <td>
                    {record['folder_folder_id'] == null ? (
                        // cssに影響するので次の行でESLint無効
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            className="icon-file copy-link-display"
                            href={'/app/fileviewer?fileId=' + record['file_file_id']}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {record['file_file_name']}
                        </a>
                    ) : (
                        // cssに影響するので次の行でESLint無効
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={(e) => this.moveFileManagement(record['folder_folder_id'])} className="icon-folder">{record['folder_folder_name']}</a>
                    )}
                    <p className="pass_box">{record['folder_name_path']}</p>

                    {/* コピーリンク出すか判定 */}
                    {(record['folder_folder_id'] == null && isAiUser) && (
                        <span className="copy-link-storage">
                            [<a className="copy-link" onClick={() => this.copyFileId(record['file_file_id'], record['file_file_name'])}>
                                ファイルIDをコピー
                            </a>]
                        </span>
                    )}
                </td>
                <td>
                    {/* ファイルかフォルダか判定 */}
                    {record['folder_folder_id'] == null ? (
                        <label>
                            {StringUtil.formatDate(new Date(record['file_update_date_time']), 'YYYY-MM-DD hh:mm')}
                            &nbsp;
                            {StringUtil.convertFileSize(record['file_file_size'])}
                        </label>
                    ) : (
                        <label>
                            {StringUtil.formatDate(new Date(record['folder_update_date_time']), 'YYYY-MM-DD hh:mm')}
                        </label>
                    )}
                </td>
                <td></td>
            </tr>
        );

        // 表を返却
        return (
            <table data-alltarget>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>名前</th>
                        <th>更新日時</th>
                        <th>並び替え</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody> 
            </table>
        )
    }


}

export default withRouter(FileSearchResultForm);
